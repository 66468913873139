body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/********** Template CSS **********/
:root {
  --primary: #06A3DA;
  --secondary: #34AD54;
  --light: #EEF9FF;
  --dark: #091E3E;
}

/*** Spinner ***/
.spinner {
  width: 40px;
  height: 40px;
  background: var(--primary);
  margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

/*** About Us ***/
.about {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-left: 60px;
}

.about-us-container {
  font-family: Arial, sans-serif;
  color: #333;
  padding: 20px;
  max-width: 95%;
  margin: 0 auto;
  background: #709ac4;
}

.hero-section {
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
  color: white;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
}

.hero-section h1 {
  font-size: 3em;
  margin: 0;
}

.content-section {
  padding-left: 30px;
  padding-top: 30px;
}

.who-are-we,
.our-goals {
  background: rgb(3, 37, 125);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-size: 1.5em;
}

.team-photo,
.goal-photo {
  width: 30%;
  height: 30%;
  border-radius: 8px;
  margin: 20px 0;
}
.team-item {
  transition: .5s;
}

.team-social {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .5s;
}

.team-social a.btn {
  position: relative;
  margin: 0 3px;
  margin-top: 100px;
  opacity: 0;
}

.team-item:hover {
  box-shadow: 0 0 30px #DDDDDD;
}

.team-item:hover .team-social {
  background: rgba(9, 30, 62, .7);
}

.team-item:hover .team-social a.btn:first-child {
  opacity: 1;
  margin-top: 0;
  transition: .3s 0s;
}

.team-item:hover .team-social a.btn:nth-child(2) {
  opacity: 1;
  margin-top: 0;
  transition: .3s .05s;
}

.team-item:hover .team-social a.btn:nth-child(3) {
  opacity: 1;
  margin-top: 0;
  transition: .3s .1s;
}

.team-item:hover .team-social a.btn:nth-child(4) {
  opacity: 1;
  margin-top: 0;
  transition: .3s .15s;
}

.team-item .team-img img,
.blog-item .blog-img img  {
  transition: .5s;
}

.team-item:hover .team-img img,
.blog-item:hover .blog-img img {
  transform: scale(1.15);
}

.statistics {
  display: flex;
  justify-content: space-around;
  margin: 5px 0;
  text-align: center;
  background: rgb(9, 23, 56);
  padding: 6px;
}

.statistics div {
  background: white;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.statistics h3 {
  margin: 0;
  font-size: 2.5em;
  color: #6a11cb;
}

.statistics p {
  margin: 5px 0 0;
  font-size: 1.2em;
}

/*** Navbar ***/
.navbar-logo {
  height: 75px;
}

.visiso {
  font-family: Arial, sans-serif;
}


.bg-body-tertiary {
  font-family: 'Nunito', sans-serif;
  position: relative;
  padding: 40px 0;
  font-size: 19px;
  font-weight: 600;
  outline: none;
  transition: .5s;
  background: url(../public/about/home.jpg);
  background-size: cover;
  filter: brightness(90%);
  width: 100%;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: 1px solid black;

}

.nav-link {
  color: #000 !important;
  text-decoration: none;
  transition: color 0.1s ease;
  padding: 10px;
}

.nav-link:hover,
.nav-link:focus {
  color: #FFFF !important;
}

.btn {
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  transition: .5s;
  margin: auto;
  padding: 10px;
}

.btn-primary {
  color: #FFFFFF;
  box-shadow: inset 0 0 0 50px transparent;
}

.btn-primary:hover {
  box-shadow: inset 0 0 0 0 var(--primary);
}

.nav-link {
  color: #000 !important;
  text-decoration: none;
  position: relative;
}

.nav-link {
  text-decoration: none; /* Remove default underline */
  position: relative; /* Position for pseudo-element */
}

.nav-link::after {
  content: ''; /* Create a pseudo-element */
  position: absolute;
  left: 0;
  bottom: -12px; /* Position the underline */
  width: 0;
  height: 2px; /* Thickness of the underline */
  background-color: #007bff; /* Underline color */
  transition: width 0.3s ease; /* Smooth transition for underline */
}

.nav-link.active::after {
  width: 100%}
/* .nav-link::after {
  position: absolute;
  left: 0;
  bottom: -12px;
  width: 100%;
  height: 2px;
  background-color: transparent;
  transition: background-color 0.5s ease;
  padding-right: 2px;
} */

.nav-link:hover::after,
.nav-link:focus::after {
  background-color: #007bff;
}

.nav-link.active::after {
  background-color: #007bff;
}

.navbar-nav {
  margin-top: auto;
}

.overlay-content {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(20, 70, 132);
  z-index: 1;
}

.content {
  max-width: 1000px;
  margin: auto;
}

.content h2 {
  font-weight: bold;
  color: #ffffff;

}

.hr {
  border: 1px solid black;
  width: 90%;
}

.sticky-top-navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

.navbar-scrolled {
  background-color: white !important;
  transition: background-color 0.3s;
}

.back-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  display: none;
  z-index: 99;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 46px;
  background: #007bff;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.3s, opacity 0.3s;
}

.back-to-top:hover {
  background-color: #0056b3;
}

.back-to-top.show {
  display: block;
}


.btn-lg-square {
  width: 48px;
  height: 48px;
  color: #FFFFFF;
}

.section-title::before {
  content: '';
  position: absolute;
  width: 150px;
  height: 5px;
  left: 0;
  bottom: 0;
  background: var(--primary);
  border-radius: 2px;
  transition: width 0.5s ease, height 0.5s ease;
}

.section-title.text-center::before {
  left: 50%;
  margin-left: -75px;
}

.section-title.section-title-sm::before {
  width: 90px;
  height: 3px;
}

.section-title::after {
  content: '';
  position: absolute;
  width: 6px;
  height: 5px;
  bottom: 0px;
  background: #ffffff;
  transition: left 5s linear;
}

.section-title.section-title-sm::after {
  width: 4px;
  height: 3px;
}

.section-title.text-center::after {
  animation: section-title-run-center 5s infinite linear;
}

.section-title.section-title-sm::after {
  animation: section-title-run-sm 5s infinite linear;
}

@keyframes section-title-run-center {
  0% {
    left: 50%;
    margin-left: -75px;
  }
  50% {
    left: 50%;
    margin-left: 45px;
  }
  100% {
    left: 50%;
    margin-left: -75px;
  }
}

@keyframes section-title-run-sm {
  0% {
    left: 0;
  }
  50% {
    left: 85px;
  }
  100% {
    left: 0;
  }
}



/*** Service ***/
.service-item {
  position: relative;
  padding: 20px; /* Adjust padding as needed */
  transition: all 0.3s ease; /* Ensure smooth transitions */
}

.service-item .service-icon {
  margin-bottom: 20px; /* Ensure space between icon and text */
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  border-radius: 50%; /* Adjust as per design */
}

.service-item .service-icon i {
  font-size: 24px; /* Adjust icon size as needed */
  transform: rotate(-30deg);
}

.service-item a.btn {
  position: absolute;
  bottom: -24px; /* Adjust position relative to parent */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.3s ease;
}

.service-item:hover a.btn {
  bottom: 0; /* Show button on hover */
  opacity: 1;
}


.gallery-imgs {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 20%;
  padding: 0 30px;

}

.gallery-imgs .gallery:hover {
  filter: opacity(.8);
}

.img-card {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 2px;
}

.gallery {
  width: 100%;
}

.gallery-btn {
  text-align: center;
  padding: 2rem 2rem;
}

.gallery-tag {
  margin: 1rem .3rem;
}

.model {
  width: 100%;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background-color: rgb(185, 215, 240);
  align-items: center;
  transition: opacity .4s, visibility .4s ease, transform .5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  max-height: 100vh;
}

.model img {
  width: 100%;
  max-width: 100% !important;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}

.close {
  position: fixed;
  top: 120px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  padding: 10px;
  background-color: rgba(24, 22, 22, 0.4);
  color: #e8dede;
  cursor: pointer;
}

.footer {
  font-family: 'Nunito', sans-serif;
  background-color: #012041;
}

@media (max-width: 768px) {
  .footer {
    padding-top: 20px;
  }
  .footer-about {
    margin-bottom: 30px;
  }
  .footer-about,
  .col-lg-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}


